



































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import userProfileModule from '@/store/modules/userProfileModule';
import notificationsModule from '@/store/modules/notificationsModule';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    UserSettings: () => getComponent('common/UserSettings'),
  },
})
export default class UserProfile extends Vue {
	savingProfile = false

	sendingInvite = false

	firstName = ''

	lastName = ''

	user_role = 'Engineers'

	team = ''

	email = ''

	phoneNumber = ''

	userInviteEmail = ''

	saveSuccessfulNotificationTitle = 'Changes saved'

	saveSuccessfulNotificationSubTitle = 'You may navigate away from this page.'

	acceptingTasqs = true

	showSaveSuccessfulNotification = false

	notifyOnNewJob = true

	notifyOnReassignedTasq = true

	loadingProfilePage = false

	username = ''

	userInitial = ''


	didToggleAcceptingTasqs() {
	  this.updateAcceptingTasqs();
	}


	get getCurrentName(): string {
		return getNameByEmail(workflowModule.user.email);
	}

	resetButtonTapped() {
		this.firstName = workflowModule.user.firstName
		this.lastName = workflowModule.user.lastName
		this.email = workflowModule.user.email
		this.phoneNumber = workflowModule.user.phoneNumber
		this.username = workflowModule.user.username
		this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0)
		this.user_role = workflowModule.user.groups[0]
	}


	async created() {
		this.loadingProfilePage = true
		await workflowModule.getUserProfile({username: accountModule.user.email})
		this.loadingProfilePage = false
		this.firstName = workflowModule.user.firstName
		this.lastName = workflowModule.user.lastName
		this.email = workflowModule.user.email
		this.phoneNumber = workflowModule.user.phoneNumber
		this.username = workflowModule.user.username
		this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0)
		this.user_role = workflowModule.user.groups[0]

	}

	sleep(ms) {
	  return new Promise((resolve) => setTimeout(resolve, ms));
	}

	async sendInvite() {
	  this.sendingInvite = true;
	  await userProfileModule.sendInvitationEmail({
	    user_email: accountModule.user.email,
	    recipient_email: this.userInviteEmail,
	    user_first_name: this.userInviteEmail,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Invitation sent';
	    this.showSaveSuccessfulNotification = true;
	    this.userInviteEmail = '';
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
	  this.sendingInvite = false;
	}

	async updateNotificationSettings() {
	  // this.savingProfile = true

	  await notificationsModule.updateNotificationSettings({
	    username: accountModule.user.email,
	    notify_on_new_job: this.notifyOnNewJob,
	    notify_on_reassigned_tasq: this.notifyOnReassignedTasq,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
	}

	async updateAcceptingTasqs() {
	  // this.savingProfile = true

	  await userProfileModule.updateAcceptingTasqs({
	    username: accountModule.user.email,
	    accepting_tasqs: this.acceptingTasqs,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
	}

	async saveProfile() {
	  this.savingProfile = true;

	  await userProfileModule.updateUserProfile({
	    username: accountModule.user.email,
	    accepting_tasqs: true,
	    first_name: this.firstName,
	    last_name: this.lastName,
	    phone_number: this.phoneNumber,
	    roles: [this.user_role],
	    user_email: accountModule.user.email,
	  }).then(() => {
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
		accountModule.getReassignmentList()
	  });
	}
}
